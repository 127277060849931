var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"login"},[_c('LoadingSpinner',{attrs:{"isLoading":_vm.isLoading}}),_c('div',{staticClass:"min-h-screen bg-primary-300 bg-opacity-50 flex items-center justify-center"},[_c('div',{staticClass:"bg-white lg:w-1/3 p-8 md:p-12 rounded-lg shadow-xl"},[_c('div',[_c('img',{staticClass:"logo w-40 mx-auto",attrs:{"src":require('@/assets/logos/logo_horizontal.png')}})]),_c('div',{staticClass:"mt-8"},[_c('main',[_c('section',{staticClass:"text-center"},[_c('h3',{staticClass:"font-bold text-2xl"},[_vm._v(_vm._s(_vm.$t("change_password_modal.change_password")))]),_c('p',{staticClass:"text-gray-600 pt-2 text-lg"},[_vm._v(_vm._s(_vm.$t("change_password_modal.change_password_title")))])]),_c('section',{staticClass:"mt-8"},[_c('ValidationObserver',{ref:"formSetPassword"},[_c('div',{staticClass:"flex flex-col"},[_c('div',[_c('label',{staticClass:"text-gray-600 text-sm font-bold pb-2 font-serif"},[_vm._v(_vm._s(_vm.$t("change_password_modal.old_password"))),_c('b',{staticClass:"text-red-500 ml-1"},[_vm._v("*")])]),_c('ValidationProvider',{attrs:{"name":"Gammalt lösenord","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"input-validate",class:classes},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.oldPassword),expression:"oldPassword"}],staticClass:"bg-gray-100 rounded w-full text-sm text-gray-900 focus:outline-none border-b-4 border-gray-300 transition duration-500 px-3 pt-2 pb-2",attrs:{"type":"password"},domProps:{"value":(_vm.oldPassword)},on:{"input":function($event){if($event.target.composing){ return; }_vm.oldPassword=$event.target.value}}}),_c('span',[_vm._v(_vm._s(errors[0]))])])]}}])})],1),_c('div',[_c('label',{staticClass:"text-gray-600 text-sm font-bold pb-2 font-serif"},[_vm._v(_vm._s(_vm.$t("change_password_modal.new_password"))),_c('b',{staticClass:"text-red-500 ml-1"},[_vm._v("*")])]),_c('ValidationProvider',{attrs:{"name":"Nytt lösenord","rules":"required","vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"input-validate",class:classes},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"bg-gray-100 rounded w-full text-sm text-gray-900 focus:outline-none border-b-4 border-gray-300 transition duration-500 px-3 pt-2 pb-2",attrs:{"type":"password"},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.password=$event.target.value}}}),_c('span',[_vm._v(_vm._s(errors[0]))])])]}}])})],1),_c('div',[_c('label',{staticClass:"text-gray-600 text-sm font-bold pb-2 font-serif"},[_vm._v(_vm._s(_vm.$t("change_password_modal.confirm_password"))),_c('b',{staticClass:"text-red-500 ml-1"},[_vm._v("*")])]),_c('ValidationProvider',{attrs:{"name":"Bekräfta lösenord","rules":"required|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"input-validate",class:classes},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.confirmPassword),expression:"confirmPassword"}],staticClass:"bg-gray-100 rounded w-full text-sm text-gray-900 focus:outline-none border-b-4 border-gray-300 transition duration-500 px-3 pt-2 pb-2",attrs:{"type":"password","data-vv-as":"password"},domProps:{"value":(_vm.confirmPassword)},on:{"input":function($event){if($event.target.composing){ return; }_vm.confirmPassword=$event.target.value}}}),_c('span',[_vm._v(_vm._s(errors[0]))])])]}}])})],1),_c('div',{staticClass:"clearfix mt-5"},[_c('div',{staticClass:"float-right"},[_c('button',{staticClass:"btn-blue px-2 py-3 text-sm mt-5",on:{"click":function($event){$event.preventDefault();return _vm.changePassword()}}},[_vm._v(" "+_vm._s(_vm.$t("change_password_modal.change_password"))+" "),_c('BaseIcon',{staticClass:"ml-1",attrs:{"icon":"key"}})],1)])])])])],1)])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }